import React from 'react';
import { FormattedMessage } from 'gatsby-plugin-intl';

import './AboutUs.scss';

const AboutUs = () => (
  <div className="about-us-page">
    <div className="line" />
    <div className="left">
      <h1 className="subtitle">
        <FormattedMessage id="aboutUsTitle" />
      </h1>
      <div className="part2 description-text">
        <FormattedMessage id="aboutUsDesc1Part1" />
      </div>
      <div className="part3 description-text">
        <FormattedMessage id="aboutUsDesc1Part2" />
      </div>
      <div className="part4 description-text">
        <FormattedMessage id="aboutUsDesc1Part3" />
      </div>
      <h2 className="subtitle">
        <FormattedMessage id="aboutUsDesc2Title" />
      </h2>
      <div className="part2 description-text">
        <FormattedMessage id="aboutUsDesc2Part1" />
      </div>
      <div className="part3 description-text">
        <FormattedMessage id="aboutUsDesc2Part2" />
      </div>
      <div className="part4 description-text">
        <FormattedMessage id="aboutUsDesc2Part3" />
      </div>
    </div>
    <div className="right">
      <img src="/images/eho-muteboard.jpg" alt="panel" className="img-responsive" />
    </div>
  </div>
);

export default AboutUs;
